import { SkeletonMain } from 'components/_commons/Skeletons';
import { Footer } from 'components/Footer/Footer';
import { Header } from 'components/Header/Header';
import React, { lazy, Suspense } from 'react';
import {
  generatePath, Navigate, Route, Routes as RouterRoutes
} from 'react-router-dom';
import { userStore } from 'stores';
import { UserHelper } from 'utils';
import {
  ALL_ROLES,
  APPLICATION_ADMIN_ACCESS_ROLES, APPLICATION_ADMIN_ROLES,
  APPLICATION_ATTESTATION_ROLES,
  APPLICATION_MANAGEMENT_ROLES,
  APPLICATION_ROLES,
  ROUTES
} from 'utils/constants';

const PageCookies = lazy(() => import('pages/PageCookies'));
const PageHome = lazy(() => import('pages/PageHome'));
const PageRGPD = lazy(() => import('pages/PageRGPD'));
const PageInstitutionAttestationList = lazy(() => import('pages/PageInstitutionAttestationList'));
const PageInstitutionDetail = lazy(() => import('pages/PageInstitutionDetail'));
const PageAttestationForm = lazy(() => import('pages/PageAttestationForm'));
const PageFluidBalance = lazy(() => import('pages/PageFluidBalance'));
const PageRequestHistoryList = lazy(() => import('pages/PageRequestHistoryList'));
const PageNoAccess = lazy(() => import('pages/PageNoAccess'));
const PageInstitutionEvaluationList = lazy(() => import('pages/PageInstitutionEvaluationList'));
const PageFluidBalanceCampaignList = lazy(() => import('pages/PageFluidBalanceCampaignList'));
const PageAuditList = lazy(() => import('pages/PageAuditList'));
const PageAdministration = lazy(() => import('pages/PageAdministration'));
const PageUserDetail = lazy(() => import('pages/PageUserDetail'));
const PageAttestationDetails = lazy(() => import('pages/PageAttestationDetails'));

const SkeletonPage = () => (
  <>
    <Header withNav />
    <SkeletonMain />
  </>
);

const ComponentWithLayout = (BaseComponent) => (
  <div style={{ height: '100vh', display: 'flex', flexDirection: 'column' }}>
    <Header withNav />
    <div style={{ flex: 1 }}>
      <BaseComponent />
    </div>
    <Footer />
  </div>
);

const ComponentWithHeaderOnly = (BaseComponent) => (
  <>
    <Header withNav />
    <BaseComponent />
  </>
);

const PrivateRoute = ({ children, allowedRoles = Object.values(APPLICATION_ROLES) }) => {
  const renderPrivateComponent = () => {
    if (UserHelper.hasAccessRight(allowedRoles)) {
      return children;
    }
    return <Navigate to={ROUTES.HOME} />;
  };

  if (!userStore.keycloak.authenticated) {
    userStore.keycloakLogin().finally(() => userStore.setIsConnecting(false));
  }

  return (
    userStore.isCurrentScopeLoading
      ? <SkeletonMain />
      : renderPrivateComponent()
  );
};

const renderInstitution = () => {
  const institutionId = UserHelper.getInstitutionIdIfAlone(userStore.currentStructureLevel, userStore.structureLevelOptions);
  if (institutionId) {
    return <Navigate to={generatePath(ROUTES.INSTITUTION.DETAIL, { institutionId })} />;
  }
  return ComponentWithLayout(PageInstitutionAttestationList);
};

export const Routes = () => (
  <Suspense fallback={SkeletonPage()}>
    <RouterRoutes>
      <Route
        element={(
          <PrivateRoute allowedRoles={APPLICATION_ADMIN_ACCESS_ROLES}>
            {ComponentWithLayout(PageAdministration)}
          </PrivateRoute>
        )}
        path={ROUTES.ADMINISTRATION.COMPANY}
      />
      <Route
        element={(
          <PrivateRoute allowedRoles={APPLICATION_ADMIN_ACCESS_ROLES}>
            {ComponentWithLayout(PageAdministration)}
          </PrivateRoute>
        )}
        path={ROUTES.ADMINISTRATION.COMPANIES}
      />
      <Route
        element={(
          <PrivateRoute allowedRoles={APPLICATION_ADMIN_ROLES}>
            {ComponentWithLayout(PageAdministration)}
          </PrivateRoute>
        )}
        path={ROUTES.ADMINISTRATION.TOOLS}
      />
      <Route
        element={(
          <PrivateRoute allowedRoles={APPLICATION_ADMIN_ROLES}>
            {ComponentWithLayout(PageAdministration)}
          </PrivateRoute>
        )}
        path={ROUTES.ADMINISTRATION.INSTITUTION_TOOLS}
      />
      <Route
        element={(
          <PrivateRoute allowedRoles={APPLICATION_ADMIN_ACCESS_ROLES}>
            {ComponentWithLayout(PageAdministration)}
          </PrivateRoute>
        )}
        path={ROUTES.ADMINISTRATION.USERS}
      />
      <Route
        element={(
          <PrivateRoute allowedRoles={APPLICATION_ADMIN_ACCESS_ROLES}>
            {ComponentWithLayout(PageAdministration)}
          </PrivateRoute>
        )}
        path={`${ROUTES.ADMINISTRATION.MAIN}`}
      />
      <Route
        element={(
          <PrivateRoute>
            {ComponentWithLayout(PageUserDetail)}
          </PrivateRoute>
        )}
        path={ROUTES.USER_DETAILS}
      />
      <Route element={ComponentWithLayout(PageRGPD)} path={ROUTES.RGPD} />
      <Route element={ComponentWithLayout(PageCookies)} path={ROUTES.COOKIES} />
      <Route
        element={(
          <PrivateRoute allowedRoles={ALL_ROLES}>
            {renderInstitution()}
          </PrivateRoute>
        )}
        path={ROUTES.INSTITUTION.ATTESTATION_LIST}
      />
      <Route
        element={(
          <PrivateRoute allowedRoles={ALL_ROLES}>
            {ComponentWithLayout(PageInstitutionDetail)}
          </PrivateRoute>
        )}
        path={ROUTES.INSTITUTION.DETAIL}
      />
      <Route
        element={(
          <PrivateRoute allowedRoles={ALL_ROLES}>
            {ComponentWithHeaderOnly(PageAttestationForm)}
          </PrivateRoute>
        )}
        path={ROUTES.ATTESTATION_FORM}
      />
      <Route
        element={(
          <PrivateRoute allowedRoles={APPLICATION_ATTESTATION_ROLES}>
            {ComponentWithLayout(PageInstitutionEvaluationList)}
          </PrivateRoute>
        )}
        path={ROUTES.INSTRUCTIONS}
      />
      <Route
        element={(
          <PrivateRoute allowedRoles={ALL_ROLES}>
            {ComponentWithLayout(PageFluidBalanceCampaignList)}
          </PrivateRoute>
        )}
        path={ROUTES.FLUID_BALANCE_CAMPAIGN}
      />
      <Route
        element={(
          <PrivateRoute allowedRoles={ALL_ROLES}>
            {ComponentWithLayout(PageAuditList)}
          </PrivateRoute>
        )}
        path={ROUTES.AUDITS}
      />
      <Route
        element={(
          <PrivateRoute allowedRoles={ALL_ROLES}>
            {ComponentWithHeaderOnly(PageFluidBalance)}
          </PrivateRoute>
        )}
        path={ROUTES.INSTITUTION.FLUID_BALANCE}
      />
      <Route
        element={(
          <>
            <Header withNav={false} />
            <PageNoAccess />
          </>
        )}
        path={ROUTES.NO_ACCESS}
      />
      <Route
        element={(
          <PrivateRoute allowedRoles={APPLICATION_MANAGEMENT_ROLES}>
            {ComponentWithHeaderOnly(PageRequestHistoryList)}
          </PrivateRoute>
        )}
        path={ROUTES.INSTITUTION.HISTORY}
      />
      <Route element={ComponentWithLayout(PageHome)} path={ROUTES.HOME} />
      <Route element={ComponentWithLayout(PageHome)} path={ROUTES.QR_CODE} />
      <Route element={ComponentWithLayout(PageAttestationDetails)} path={ROUTES.ATTESTATION_DETAILS} />
      <Route element={<Navigate index to={ROUTES.HOME} />} path="*" />
    </RouterRoutes>
  </Suspense>
);