/* eslint-disable max-len */
import {
  faBuilding,
  faSitemap,
  faStoreAlt
} from '@fortawesome/pro-light-svg-icons';
import {
  SectionEnum
} from 'components/AttestationForm/attestationFormSection.enum';
import { iconEnum } from 'utils/icons';

/* eslint max-len: ["error", { "ignoreTemplateLiterals": true }] */
// eslint-disable-next-line no-underscore-dangle
const getEnv = (key) => (window._env && window._env[`REACT_APP_${key}`])
  || process.env[`REACT_APP_${key}`]
  || '';

const setCurrentVersion = (version = 1) => `v${version}`;
const setDatabilanCurrentVersion = (version = 1) => `v${version}`;

export const ROOT_PATH = getEnv('ROOT_PATH');
export const API_URL = getEnv('API_URL');
export const API_URL_DATATECNEA = getEnv('API_URL_DATATECNEA');
export const API_URL_DATABILAN = getEnv('API_URL_DATABILAN');
export const URL_DATATECNEA = getEnv('URL_DATATECNEA');
export const INSEE_API_TOKEN = getEnv('INSEE_API_TOKEN');
export const MAPBOX_TOKEN = getEnv('MAPBOX_TOKEN');

export const KEYCLOAK_REALM = getEnv('KEYCLOAK_REALM');
export const KEYCLOAK_AUTH_SERVER_URL = getEnv('KEYCLOAK_AUTH_SERVER_URL');
export const KEYCLOAK_RESOURCE = getEnv('KEYCLOAK_RESOURCE');

export const RGPD_EMAIL = 'rgpd@tecnea.fr';
export const ASSISTANCE_EMAIL = 'certification@cemafroid.fr';

export const APP_VERSION = getEnv('VERSION');
export const ENVIRONMENT = getEnv('ENVIRONMENT');
export const CREATED = getEnv('CREATED');
export const REVISION = getEnv('REVISION');
export const GA4_TAG_ID = getEnv('TAG_MANAGER_ID');

export const LIST_SIZE = 20;
export const MAX_FILE_SIZE = 10; // in Mo
export const LIMIT_ELEM_FOR_MASS_ACTION = 1000;

export const GOOGLE_MAPS = 'https://www.google.com/maps/search/?api=1&query=';

export const ROUTES = {
  HOME: '/',
  ATTESTATION_DETAILS: '/attestation-details/:attestationNumber',
  QR_CODE: '/qrcode',
  NO_ACCESS: '/no-access',
  PROFILE: '/profile/:userAccountHashId',
  RGPD: '/legal-mention',
  COOKIES: '/manage-cookies',
  INSTITUTION: {
    ATTESTATION_LIST: '/institutions/attestation-list',
    DETAIL: '/institutions/:institutionId',
    FLUID_BALANCE: '/institutions/:institutionId/fluid-balance',
    HISTORY: '/institutions/:institutionId/history'
  },
  ATTESTATION_FORM: '/attestation-form/:requestId',
  INSTRUCTIONS: '/instructions',
  FLUID_BALANCE_CAMPAIGN: '/fluid-balance-campaign',
  USER_DETAILS: '/users/:personId',
  AUDITS: '/audits',
  ADMINISTRATION: {
    COMPANY: '/administration/companies/:companyId',
    COMPANIES: '/administration/companies',
    TOOLS: '/administration/institutions-tool-list/:institutionId',
    INSTITUTION_TOOLS: '/administration/institutions-tool-list',
    USERS: '/administration/users',
    MAIN: '/administration'
  }
};

export const BASE_API_ROUTES = {
  COMPANIES: `${API_URL}/${setCurrentVersion()}/companies`,
  USER_ACCOUNTS: `${API_URL}/${setCurrentVersion()}/user-accounts`,
  INSTITUTIONS: `${API_URL}/${setCurrentVersion()}/institutions`,
  REQUESTS: `${API_URL}/${setCurrentVersion()}/requests`,
  OPERATORS: `${API_URL}/${setCurrentVersion()}/operators`,
  TOOLS: `${API_URL}/${setCurrentVersion()}/tools`,
  ATTESTATION_FORM_TOOLS: `${API_URL}/${setCurrentVersion()}/attestation-form-tools`,
  ENUM_OPTIONS: `${API_URL}/${setCurrentVersion()}/enum-options`,
  FLUID_BALANCES: `${API_URL}/${setCurrentVersion()}/fluid-balances`,
  FLUIDS: `${API_URL}/${setCurrentVersion()}/fluids`,
  FLUID_BALANCE_CAMPAIGN: `${API_URL}/${setCurrentVersion()}/fluid-balance-campaigns`,
  // SetCurrentVersion use Datafluides version, but Databilan may not have the same API version
  DATABILAN_FLUID_BALANCE: `${API_URL_DATABILAN}/${setDatabilanCurrentVersion()}/fluid-balances`,
  DOCUMENTS: `${API_URL}/${setCurrentVersion()}/documents`,
  IMPORT_FILES: `${API_URL}/${setCurrentVersion()}/import-files`,
  ALERTS: `${API_URL}/${setCurrentVersion()}/alerts`,
  CONTACTS: `${API_URL}/${setCurrentVersion()}/contacts`,
  TRANSLATIONS: `${API_URL}/${setCurrentVersion()}/translations`,
  FLUID_BALANCE_ENTRIES: `${API_URL}/${setCurrentVersion()}/fluid-balance-entries`,
  PERSON: `${API_URL}/${setCurrentVersion()}/persons`,
  INSTITUTION_GROUP: `${API_URL}/${setCurrentVersion()}/institution-groups`,
  COMPANY: `${API_URL}/${setCurrentVersion()}/companies`,
  COUNTRY: `${API_URL}/${setCurrentVersion()}/countries`,
  ATTESTATION_EVALUATIONS: `${API_URL}/${setCurrentVersion()}/attestation-evaluations`,
  AUDIT: `${API_URL}/${setCurrentVersion()}/audits`,
  ATTESTATION: `${API_URL}/${setCurrentVersion()}/attestations`,
  CERTIFICATION_PROGRAM: `${API_URL}/${setCurrentVersion()}/certification-program`,
  FLUID_BALANCE_DOCUMENTS: `${API_URL}/${setCurrentVersion()}/fluid-balance-documents`
};

export const API_ROUTES = {
  COMPANIES: {
    DETAIL: (companyId) => `${BASE_API_ROUTES.COMPANIES}/${companyId}/structureLevels`,
    ACCESSIBLE_COMPANIES: () => `${BASE_API_ROUTES.COMPANIES}/accessibleCompanies`
  },
  ACTIVITY: () => `${API_URL}/${setCurrentVersion()}/activities`,

  USER_ACCOUNTS: {
    GET_ALL: (page) => `${BASE_API_ROUTES.USER_ACCOUNTS}/filter?page=${page}`,
    CREATE: () => `${BASE_API_ROUTES.USER_ACCOUNTS}`,
    UPDATE: (userId) => `${BASE_API_ROUTES.USER_ACCOUNTS}/${userId}`,
    UPDATE_ROLES: (userId) => `${BASE_API_ROUTES.USER_ACCOUNTS}/${userId}/roles`,
    ROLES_BY_STRUCTURE_LEVEL: (companyId) => `${BASE_API_ROUTES.USER_ACCOUNTS}/${companyId}/rolesByStructureLevel`,
    CURRENT_SCOPE: () => `${BASE_API_ROUTES.USER_ACCOUNTS}/current/scope`,
    UPDATE_LEVEL: () => `${BASE_API_ROUTES.USER_ACCOUNTS}/current/level`,
    CURRENT_STRUCTURE_LEVEL_OPTIONS: () => `${BASE_API_ROUTES.USER_ACCOUNTS}/current/structure_level/options`,
    DETAIL: (userAccountHashId) => `${BASE_API_ROUTES.USER_ACCOUNTS}/${userAccountHashId}`,
    UPDATE_LOCALE: (locale) => `${BASE_API_ROUTES.USER_ACCOUNTS}/locale?locale=${locale}`,
    COMPANY_CHILDREN_OPTIONS: () => `${BASE_API_ROUTES.USER_ACCOUNTS}/current/company/children-options`,
    RESET_PASSWORD: () => `${BASE_API_ROUTES.USER_ACCOUNTS}/resetPassword`
  },

  INSTITUTIONS: {
    ATTESTATION_SUMMARIES: (page) => `${BASE_API_ROUTES.INSTITUTIONS}/attestation-summaries?page=${page}`,
    TOOL_SUMMARIES: (page) => `${BASE_API_ROUTES.INSTITUTIONS}/tool-summaries?page=${page}`,
    DETAILS: (institutionId) => `${BASE_API_ROUTES.INSTITUTIONS}/details/${institutionId}`,
    REQUEST: (institutionId) => `${BASE_API_ROUTES.INSTITUTIONS}/${institutionId}/request`,
    INITIAL_REQUEST: (institutionId) => `${BASE_API_ROUTES.INSTITUTIONS}/${institutionId}/initial-request`,
    RENEWAL_REQUEST: (institutionId) => `${BASE_API_ROUTES.INSTITUTIONS}/${institutionId}/renewal-request`,
    ATTESTATION: (institutionId) => `${BASE_API_ROUTES.INSTITUTIONS}/${institutionId}/attestation`,
    BILAN: (institutionId) => `${BASE_API_ROUTES.INSTITUTIONS}/${institutionId}/fluid-balance`,
    BILAN_SUMMARY_ALERTS: (institutionId) => `${BASE_API_ROUTES.INSTITUTIONS}/${institutionId}/fluid-balance-summary-alerts`,
    AUDIT: (institutionId) => `${BASE_API_ROUTES.INSTITUTIONS}/${institutionId}/audit`,
    AUDITS: (institutionId) => `${BASE_API_ROUTES.INSTITUTIONS}/${institutionId}/audits`,
    LEGACY_AUDITS: (institutionId) => `${BASE_API_ROUTES.INSTITUTIONS}/${institutionId}/legacy-audits`,
    TREE: (institutionId, activity) => `${BASE_API_ROUTES.INSTITUTIONS}/${institutionId}/tree?activity=${activity}`,
    FILTER: (page) => `${BASE_API_ROUTES.COMPANY}/filter?page=${page}`,
    OPTIONS: () => `${BASE_API_ROUTES.INSTITUTIONS}/options`,
    STATUS_COUNT: (entity) => `${BASE_API_ROUTES.INSTITUTIONS}/status-count/${entity}`,
    ADMINISTRATION: (institutionId) => `${BASE_API_ROUTES.INSTITUTIONS}/${institutionId}`,
    HISTORY: (institutionModelId) => `${BASE_API_ROUTES.INSTITUTIONS}/${institutionModelId}/history-events`,
    SECTION_HISTORY: (institutionModelId) => `${BASE_API_ROUTES.INSTITUTIONS}/${institutionModelId}/request-last-history`,
    SAVE: () => `${BASE_API_ROUTES.INSTITUTIONS}`,
    NOT_EDITABLE: (companyId) => `${BASE_API_ROUTES.INSTITUTIONS}/${companyId}/not-editable`,
    SYDEREP: () => `${BASE_API_ROUTES.INSTITUTIONS}/export-syderep`,
    EXPANDED_INSTITUTIONS_EXPORT: () => `${BASE_API_ROUTES.INSTITUTIONS}/export-expanded-institutions`,
    EXPANDED_INSTITUTIONS_EXPORT_BY_MAIL: () => `${BASE_API_ROUTES.INSTITUTIONS}/export-expanded-institutions-by-mail`
  },

  INSTITUTION_GROUP: {
    ADMINISTRATION: (institutionGroupId) => `${BASE_API_ROUTES.INSTITUTION_GROUP}/${institutionGroupId}`,
    SAVE: (activity) => `${BASE_API_ROUTES.INSTITUTION_GROUP}?activity=${activity}`,
    UPDATE: (institutionGroupId, activity) => `${BASE_API_ROUTES.INSTITUTION_GROUP}/${institutionGroupId}?activity=${activity}`
  },

  COMPANY: {
    ADMINISTRATION: (companyId) => `${BASE_API_ROUTES.COMPANY}/${companyId}`,
    FROM_INSTITUTION_MODEL: (institutionModelId) => `${BASE_API_ROUTES.COMPANY}?institutionModelId=${institutionModelId}`,
    SAVE: () => `${BASE_API_ROUTES.COMPANY}`,
    FILTER: (page) => `${BASE_API_ROUTES.COMPANY}/filter?page=${page}`,
    OPTIONS: (page) => `${BASE_API_ROUTES.COMPANY}/options?page=${page}`,
    TREE: (companyId, activity) => `${BASE_API_ROUTES.COMPANY}/${companyId}/tree?activity=${activity}`,
    IS_PUBLIC: (companyId) => `${BASE_API_ROUTES.COMPANY}/${companyId}/public`,
    TREE_PARENT_COMPANY: (companyId) => `${BASE_API_ROUTES.COMPANY}/${companyId}/parent`
  },

  COUNTRY: {
    IDENTIFIERS: (countryId) => `${BASE_API_ROUTES.COUNTRY}/${countryId}/identifiers`,
    GET: (countryId) => `${BASE_API_ROUTES.COUNTRY}/${countryId}`,
    SELECT_ITEMS: () => `${BASE_API_ROUTES.COUNTRY}/select-items`
  },

  LEGAL_STATUS: {
    COUNTRY: (countryId) => `${API_URL}/${setCurrentVersion()}/legal-status/country/${countryId}`
  },

  STRUCTURE_LEVEL: {
    MOVE: () => `${API_URL}/${setCurrentVersion()}/structure-levels/move`
  },

  REQUESTS: {
    FORM_REQUEST_INSTRUCTIONS: () => `${BASE_API_ROUTES.REQUESTS}/filter-instructions`,
    FORM_REQUEST: (requestId) => `${BASE_API_ROUTES.REQUESTS}/${requestId}`,
    PURCHASE_ORDER_TEMPLATE: (requestId) => `${BASE_API_ROUTES.REQUESTS}/${requestId}/purchase-order-template`,
    OPERATOR: (requestId) => `${BASE_API_ROUTES.REQUESTS}/${requestId}/operator`,
    TOOL: (requestId) => `${BASE_API_ROUTES.REQUESTS}/${requestId}/tool`,
    OPERATORS: (requestId) => `${BASE_API_ROUTES.REQUESTS}/${requestId}/operators`,
    ORGANIZATION: (requestId) => `${BASE_API_ROUTES.REQUESTS}/${requestId}/work_mode`,
    UPDATE_ORGANIZATION: (requestId, mode) => `${BASE_API_ROUTES.REQUESTS}/${requestId}/work_mode?mode=${mode}`,
    EQUIPMENT_DOCUMENTS: (requestId, type, freeSearch) => `${BASE_API_ROUTES.REQUESTS}/${requestId}/tools?type=${type}&freeSearch=${freeSearch}`,
    INSTITUTION_TOOLS: (requestId) => `${BASE_API_ROUTES.REQUESTS}/${requestId}/institution-tools`,
    CATEGORIES: (requestId) => `${BASE_API_ROUTES.REQUESTS}/${requestId}/categories`,
    VALIDATE_ALERTS: (requestId) => `${BASE_API_ROUTES.REQUESTS}/${requestId}/validate-alerts`,
    VALIDATE_SECTIONS: (requestId) => `${BASE_API_ROUTES.REQUESTS}/${requestId}/validate-all-alerts`,
    MODIFY: (requestId) => `${BASE_API_ROUTES.REQUESTS}/${requestId}/modify`,
    SUBMIT: (requestId) => `${BASE_API_ROUTES.REQUESTS}/${requestId}/submit`,
    CLOSE: (requestId) => `${BASE_API_ROUTES.REQUESTS}/${requestId}/close`,
    UPDATE: (requestId) => `${BASE_API_ROUTES.REQUESTS}/${requestId}/update`,
    PURCHASE_ORDER_REFERENCE: (requestId) => `${BASE_API_ROUTES.REQUESTS}/${requestId}/purchase-order-reference`,
    MASS_CLOSE: () => `${BASE_API_ROUTES.REQUESTS}/mass-close`,
    UNCLASSIFIED_DOCUMENTS: (institutionId, type) => `${BASE_API_ROUTES.REQUESTS}/${institutionId}/unclassified-documents?type=${type}`,
    SITUATION_UPDATE: (requestId) => `${BASE_API_ROUTES.REQUESTS}/${requestId}/situation-update`,
    INVALIDATE_PURCHASE_ORDER: (requestId) => `${BASE_API_ROUTES.REQUESTS}/${requestId}/invalidate-purchase-order`,
    ORGANIZATION_TOOLTIPS: (requestId) => `${BASE_API_ROUTES.REQUESTS}/${requestId}/work_mode/tooltips`
  },
  ATTESTATION_EVALUATIONS: {
    FILTER: (page) => `${BASE_API_ROUTES.ATTESTATION_EVALUATIONS}/filter?page=${page}`,
    FILTER_ASSIGNED: () => `${BASE_API_ROUTES.ATTESTATION_EVALUATIONS}/assigned/filter`,
    COUNT_BY_STATUS: () => `${BASE_API_ROUTES.ATTESTATION_EVALUATIONS}/status-count`,
    STATUS_OPTIONS: () => `${BASE_API_ROUTES.ATTESTATION_EVALUATIONS}/status/options`,
    EVALUATOR_OPTIONS: (requestId, evaluatorType) => `${BASE_API_ROUTES.ATTESTATION_EVALUATIONS}/request/${requestId}/${evaluatorType}/options`,
    ALL_EVALUATOR_OPTIONS: (evaluatorType) => `${BASE_API_ROUTES.ATTESTATION_EVALUATIONS}/request/${evaluatorType}/options`,
    ASSIGN_EVALUATOR: (requestId, evaluatorType) => `${BASE_API_ROUTES.ATTESTATION_EVALUATIONS}/request/${requestId}/${evaluatorType}`,
    UNREAD_DOCUMENTS_ALERTS: (requestId) => `${BASE_API_ROUTES.ATTESTATION_EVALUATIONS}/request/${requestId}/unread-document-alerts`,
    START_INSTRUCTION: (requestId) => `${BASE_API_ROUTES.ATTESTATION_EVALUATIONS}/request/${requestId}/instruction/start`,
    VALIDATE_INSTRUCTION: (requestId) => `${BASE_API_ROUTES.ATTESTATION_EVALUATIONS}/request/${requestId}/instruction/validate`,
    DISMISS_INSTRUCTION: (requestId) => `${BASE_API_ROUTES.ATTESTATION_EVALUATIONS}/request/${requestId}/instruction/dismiss`,
    REJECT_INSTRUCTION: (requestId) => `${BASE_API_ROUTES.ATTESTATION_EVALUATIONS}/request/${requestId}/instruction/reject`,
    START_SUPERVISION: (requestId) => `${BASE_API_ROUTES.ATTESTATION_EVALUATIONS}/request/${requestId}/supervision/start`,
    VALIDATE_SUPERVISION: (requestId) => `${BASE_API_ROUTES.ATTESTATION_EVALUATIONS}/request/${requestId}/supervision/validate`,
    DISMISS_SUPERVISION_OPERATOR: (requestId) => `${BASE_API_ROUTES.ATTESTATION_EVALUATIONS}/request/${requestId}/supervision/dismiss_operator`,
    DISMISS_SUPERVISION_INSTRUCTOR: (requestId) => `${BASE_API_ROUTES.ATTESTATION_EVALUATIONS}/request/${requestId}/supervision/dismiss_instructor`,
    REJECT_SUPERVISION: (requestId) => `${BASE_API_ROUTES.ATTESTATION_EVALUATIONS}/request/${requestId}/supervision/reject`,
    EXPORT_BY_MAIL: () => `${BASE_API_ROUTES.ATTESTATION_EVALUATIONS}/notif-export`,
    EXPORT: () => `${BASE_API_ROUTES.ATTESTATION_EVALUATIONS}/export`,
    IMPORT: () => `${BASE_API_ROUTES.ATTESTATION_EVALUATIONS}/import`
  },
  OPERATORS: {
    DOCUMENTS_TYPE: () => `${BASE_API_ROUTES.OPERATORS}/documents_type`,
    CATEGORIES_BY_DOCUMENT_TYPE: (parentId) => `${BASE_API_ROUTES.OPERATORS}/documents_type/parent_type/${parentId}`,
    LINK_DOCUMENT: (operatorId) => `${BASE_API_ROUTES.OPERATORS}/${operatorId}/link-document`,
    OPERATOR: (operatorId) => `${BASE_API_ROUTES.OPERATORS}/${operatorId}`,
    UPDATE_WORK_MODE: (operatorId, mode) => `${BASE_API_ROUTES.OPERATORS}/${operatorId}/work_mode?mode=${mode}`,
    DEACTIVATE: (operatorId, activate) => `${BASE_API_ROUTES.OPERATORS}/${operatorId}/toggle_activation?activated=${activate}`,
    IMPORT_ATTESTATION_FORM_OPERATORS: (requestId) => `${BASE_API_ROUTES.OPERATORS}/import?requestId=${requestId}`,
    EXPORT_ATTESTATION_FORM_OPERATORS: (requestId) => `${BASE_API_ROUTES.OPERATORS}/export?requestId=${requestId}`
  },
  TOOLS: {
    EQUIPMENT_TYPES: () => `${BASE_API_ROUTES.TOOLS}/equipment-types`,
    EQUIPMENT_TYPES_FOR_REQUEST: (requestId) => `${BASE_API_ROUTES.TOOLS}/equipment-types/${requestId}`,
    BRANDS: () => `${BASE_API_ROUTES.TOOLS}/brands`,
    INSTITUTION_TOOLS: (institutionId, page) => `${BASE_API_ROUTES.TOOLS}/filter/${institutionId}?page=${page}`,
    TOOL: (toolId) => `${BASE_API_ROUTES.TOOLS}/${toolId}`,
    TOOL_ACTIVATION: (toolId) => `${BASE_API_ROUTES.TOOLS}/${toolId}/toggle-activation`,
    BASE_TOOL: (institutionId) => `${BASE_API_ROUTES.TOOLS}?institutionModelId=${institutionId}`,
    DETENTION_DOCUMENT_TYPES: (isNew = false, toolType = null) => `${BASE_API_ROUTES.ATTESTATION_FORM_TOOLS}/document-types/detention?isNew=${isNew}${toolType ? `&toolType=${toolType}` : ''}`,
    VERIFICATION_DOCUMENT_TYPES: (isNew = false) => `${BASE_API_ROUTES.ATTESTATION_FORM_TOOLS}/document-types/verification?isNew=${isNew}`,
    SPECIFIC_DOCUMENT_TYPES: (type) => `${BASE_API_ROUTES.ATTESTATION_FORM_TOOLS}/document-types/specific-types?toolType=${type}`,
    LINK_DOCUMENT: (toolId) => `${BASE_API_ROUTES.ATTESTATION_FORM_TOOLS}/${toolId}/link-document`,
    ATTESTATION_FORM_TOOL: (toolId) => `${BASE_API_ROUTES.ATTESTATION_FORM_TOOLS}/${toolId}`,
    AVAILABLE_DOCUMENT_TYPES: (type) => `${BASE_API_ROUTES.ATTESTATION_FORM_TOOLS}/document-types?toolType=${type}`,
    ALL_AVAILABLE_DOCUMENT_TYPES: () => `${BASE_API_ROUTES.ATTESTATION_FORM_TOOLS}/document-types`,
    GET_ATTESTATION_FORM_TOOLS: (requestId) => `${BASE_API_ROUTES.ATTESTATION_FORM_TOOLS}?requestId=${requestId}`,
    ASSIGN_DOCUMENT_TO_TOOLS: () => `${BASE_API_ROUTES.ATTESTATION_FORM_TOOLS}/assign-document-tools`,
    TOGGLE_ACTIVATION: (toolId, activate) => `${BASE_API_ROUTES.ATTESTATION_FORM_TOOLS}/${toolId}/toggle_activation?activated=${activate}`,
    IMPORT_ATTESTATION_FORM_TOOLS: (requestId) => `${BASE_API_ROUTES.ATTESTATION_FORM_TOOLS}/import?requestId=${requestId}`,
    EXPORT_ATTESTATION_FORM_TOOLS: (requestId) => `${BASE_API_ROUTES.ATTESTATION_FORM_TOOLS}/export?requestId=${requestId}`
  },
  ENUM_OPTIONS: {
    OPTIONS: (enumName) => `${BASE_API_ROUTES.ENUM_OPTIONS}/${enumName}`,
    VALUES: (enumName) => `${BASE_API_ROUTES.ENUM_OPTIONS}/${enumName}/values`,
    TOOLTIPS: (enumName) => `${BASE_API_ROUTES.ENUM_OPTIONS}/${enumName}/tooltips`
  },
  FLUID_BALANCES: {
    FLUID_BALANCE: (fluidBalanceId) => `${BASE_API_ROUTES.FLUID_BALANCES}/${fluidBalanceId}`,
    ENTRY: (fluidBalanceId) => `${BASE_API_ROUTES.FLUID_BALANCES}/${fluidBalanceId}/entry`,
    INSTITUTION_ID_BY_EDITABLE_BALANCE: (year) => `${BASE_API_ROUTES.FLUID_BALANCES}/filter-editable-institution?year=${year}`,
    OPTIONS: (institutionId) => `${BASE_API_ROUTES.FLUID_BALANCES}/options?institutionId=${institutionId}`,
    EVALUATOR_OPTIONS: (fluidBalanceId, role) => `${BASE_API_ROUTES.FLUID_BALANCES}/${fluidBalanceId}/${role}/options`,
    ASSIGN_EVALUATOR: (fluidBalanceId, role) => `${BASE_API_ROUTES.FLUID_BALANCES}/${fluidBalanceId}/${role}`,
    INSTRUCTORS: () => `${BASE_API_ROUTES.FLUID_BALANCES}/instructors`,
    SUPERVISORS: () => `${BASE_API_ROUTES.FLUID_BALANCES}/supervisors`,
    SUBMIT: (fluidBalanceId) => `${BASE_API_ROUTES.FLUID_BALANCES}/${fluidBalanceId}/submit`,
    START_INSTRUCTION: (fluidBalanceId) => `${BASE_API_ROUTES.FLUID_BALANCES}/${fluidBalanceId}/instruction/start`,
    VALIDATE_INSTRUCTION: (fluidBalanceId) => `${BASE_API_ROUTES.FLUID_BALANCES}/${fluidBalanceId}/instruction/validate`,
    REJECT: (fluidBalanceId) => `${BASE_API_ROUTES.FLUID_BALANCES}/${fluidBalanceId}/reject`,
    START_SUPERVISION: (fluidBalanceId) => `${BASE_API_ROUTES.FLUID_BALANCES}/${fluidBalanceId}/supervision/start`,
    VALIDATE_SUPERVISION: (fluidBalanceId) => `${BASE_API_ROUTES.FLUID_BALANCES}/${fluidBalanceId}/supervision/validate`,
    DISMISS_SUPERVISION: (fluidBalanceId) => `${BASE_API_ROUTES.FLUID_BALANCES}/${fluidBalanceId}/supervision/dismiss`,
    SEND_BACK_TO_OPERATOR: (fluidBalanceId) => `${BASE_API_ROUTES.FLUID_BALANCES}/${fluidBalanceId}/send-back`,
    GET_BACK_TO_EDITION: (fluidBalanceId) => `${BASE_API_ROUTES.FLUID_BALANCES}/${fluidBalanceId}/get-back`,
    SYDEREP: () => `${BASE_API_ROUTES.FLUID_BALANCES}/export-syderep`,
    BALANCES_FROM_DATABILAN: () => `${BASE_API_ROUTES.DATABILAN_FLUID_BALANCE}/for-operator`,
    SYNCHRONIZE: (fluidBalanceId) => `${BASE_API_ROUTES.FLUID_BALANCES}/${fluidBalanceId}/synchronize`,
    UNLOCK_ALL: () => `${BASE_API_ROUTES.FLUID_BALANCES}/unlock-all`
  },
  FLUIDS: {
    OPTIONS: () => `${BASE_API_ROUTES.FLUIDS}/options`
  },
  FLUID_BALANCE_CAMPAIGN: {
    FILTER: (page) => `${BASE_API_ROUTES.FLUID_BALANCE_CAMPAIGN}/filter?page=${page}`,
    FILTER_ASSIGNED: () => `${BASE_API_ROUTES.FLUID_BALANCE_CAMPAIGN}/assigned/filter`,
    CLOSE: () => `${BASE_API_ROUTES.FLUID_BALANCE_CAMPAIGN}/close`,
    LOCK: () => `${BASE_API_ROUTES.FLUID_BALANCE_CAMPAIGN}/lock`,
    EDIT: () => `${BASE_API_ROUTES.FLUID_BALANCE_CAMPAIGN}/edit`,
    EXPORT_FOR_OPERATOR: () => `${BASE_API_ROUTES.FLUID_BALANCE_CAMPAIGN}/export-for-operator`,
    EXPORT_BY_MAIL_FOR_OPERATOR: () => `${BASE_API_ROUTES.FLUID_BALANCE_CAMPAIGN}/notif-export-for-operator`,
    EXPORT_SYNTHESIS_FOR_BACK_OFFICE: () => `${BASE_API_ROUTES.FLUID_BALANCE_CAMPAIGN}/export-synthesis`,
    EXPORT_SYNTHESIS_BY_MAIL_FOR_BACK_OFFICE: () => `${BASE_API_ROUTES.FLUID_BALANCE_CAMPAIGN}/notif-export-synthesis`,
    EXPORT_DETAILED_FOR_BACK_OFFICE: () => `${BASE_API_ROUTES.FLUID_BALANCE_CAMPAIGN}/export-detail`,
    EXPORT_DETAILED_BY_MAIL_FOR_BACK_OFFICE: () => `${BASE_API_ROUTES.FLUID_BALANCE_CAMPAIGN}/notif-export-detail`,
    MASS_REJECT: () => `${BASE_API_ROUTES.FLUID_BALANCE_CAMPAIGN}/mass-reject`,
    MASS_VALIDATE: () => `${BASE_API_ROUTES.FLUID_BALANCE_CAMPAIGN}/mass-validate`,
    MASS_SYNCHRONIZE: (year) => `${BASE_API_ROUTES.FLUID_BALANCE_CAMPAIGN}/mass-synchronize?year=${year}`,
    IMPORT_MASS_DISABLE_ATTESTATIONS: () => `${BASE_API_ROUTES.FLUID_BALANCE_CAMPAIGN}/import-mass-disable-attestations`
  },
  DOCUMENTS: {
    DOCUMENT: (documentId) => `${BASE_API_ROUTES.DOCUMENTS}/${documentId}`,
    VALIDATION: (documentId) => `${BASE_API_ROUTES.DOCUMENTS}/${documentId}/validation`,
    TYPE: (documentId, type) => `${BASE_API_ROUTES.DOCUMENTS}/${documentId}/type?type=${type}`,
    FILE_TRANSFER: (documentId) => `${BASE_API_ROUTES.DOCUMENTS}/${documentId}/file-transfer`,
    CONTENT: () => `${BASE_API_ROUTES.DOCUMENTS}/content`
  },
  IMPORT_FILES: {
    EXAMPLE: (fileIdentifier) => `${BASE_API_ROUTES.IMPORT_FILES}/example/${fileIdentifier}`
  },
  ALERTS: {
    ALERT: (alertId) => `${BASE_API_ROUTES.ALERTS}/${alertId}`
  },
  CONTACTS: {
    CONTACT: (contactId) => `${BASE_API_ROUTES.CONTACTS}/${contactId}`,
    CONTACT_TYPES: () => `${BASE_API_ROUTES.CONTACTS}/types`
  },
  TRANSLATIONS: {
    LABELS: (application, locale) => `${BASE_API_ROUTES.TRANSLATIONS}/front-labels?application=${application}&locale=${locale}`,
    LANGUAGE_LIST: () => `${BASE_API_ROUTES.TRANSLATIONS}/languages`
  },
  FLUID_BALANCE_ENTRIES: {
    FLUID_BALANCE_ENTRY: (entryId) => `${BASE_API_ROUTES.FLUID_BALANCE_ENTRIES}/${entryId}`
  },
  FLUID_BALANCE_DOCUMENTS: {
    DOCUMENTS: (fluidBalanceId) => `${BASE_API_ROUTES.FLUID_BALANCE_DOCUMENTS}/${fluidBalanceId}/documents`,
    DOCUMENT: (documentId) => `${BASE_API_ROUTES.FLUID_BALANCE_DOCUMENTS}/documents/${documentId}`
  },
  PERSONS: {
    PERSON: (personId) => `${BASE_API_ROUTES.PERSON}/${personId}`
  },
  AUDIT: {
    TYPES: () => `${BASE_API_ROUTES.AUDIT}/types`,
    RESULTS: () => `${BASE_API_ROUTES.AUDIT}/results`,
    AUDITORS: () => `${BASE_API_ROUTES.AUDIT}/auditors`,
    UPDATE: (auditId) => `${BASE_API_ROUTES.AUDIT}/${auditId}`,
    FILTER: (page) => `${BASE_API_ROUTES.AUDIT}/filter?page=${page}`,
    EXPORT_MAIL: () => `${BASE_API_ROUTES.AUDIT}/notif-export`,
    EXPORT: () => `${BASE_API_ROUTES.AUDIT}/export`,
    DELETE: (auditId) => `${BASE_API_ROUTES.AUDIT}/${auditId}`
  },

  APPLICATION_ROLE: `${API_URL}/${setCurrentVersion()}/application-roles/options`,

  ATTESTATION: {
    GET_BY_NUMBER: (attestationNumber) => `${BASE_API_ROUTES.ATTESTATION}/${attestationNumber}`,
    GET_DETAILS_NUMBER: (attestationNumber) => `${BASE_API_ROUTES.ATTESTATION}/${attestationNumber}/details`,
    DOWNLOAD: (attestationId) => `${BASE_API_ROUTES.ATTESTATION}/${attestationId}/download`,
    DISABLE: (attestationId) => `${BASE_API_ROUTES.ATTESTATION}/${attestationId}/disable`,
    RESTORE: (attestationId) => `${BASE_API_ROUTES.ATTESTATION}/${attestationId}/restore`,
    REACTIVATE: (attestationId) => `${BASE_API_ROUTES.ATTESTATION}/${attestationId}/reactivate`,
    MASS_REACTIVATE: () => `${BASE_API_ROUTES.ATTESTATION}/mass-reactivate`,
    MASS_DISABLE: () => `${BASE_API_ROUTES.ATTESTATION}/mass-disable`
  },

  CERTIFICATION: {
    PROGRAM: () => `${BASE_API_ROUTES.CERTIFICATION_PROGRAM}`
  },

  LOG_ERRORS: `${API_URL}/${setCurrentVersion()}/logs`,
  HEALTH_CHECK: `${API_URL}/actuator/health`,
  PING: `${API_URL}/ping`
};

export const ATTESTATION_FORM_ACTIONS = {
  CAN_EDIT_FORM: 'CAN_EDIT_FORM',
  CAN_EDIT_FORM_TOOL_STEP: 'CAN_EDIT_FORM_TOOL_STEP',
  CAN_EDIT_PURCHASE_ORDER: 'CAN_EDIT_PURCHASE_ORDER',
  CAN_CREATE_ALERT: 'CAN_CREATE_ALERT',
  CAN_CLOSE_ALERT: 'CAN_CLOSE_ALERT',
  CAN_REOPEN_ALERT: 'CAN_REOPEN_ALERT',
  CAN_RESOLVE_ALERT_AS_OPERATOR: 'CAN_RESOLVE_ALERT_AS_OPERATOR',
  CAN_RESOLVE_ALERT_AS_INSTRUCTOR: 'CAN_RESOLVE_ALERT_AS_INSTRUCTOR',
  CAN_EDIT_DOCUMENT_VALIDATION: 'CAN_EDIT_DOCUMENT_VALIDATION',
  CAN_START_INSTRUCTION: 'CAN_START_INSTRUCTION',
  CAN_START_SUPERVISION: 'CAN_START_SUPERVISION',
  CAN_INSTRUCT: 'CAN_INSTRUCT',
  CAN_SUPERVISE: 'CAN_SUPERVISE',
  CAN_MODIFY_REQUEST_OPERATOR: 'CAN_MODIFY_REQUEST_OPERATOR',
  CAN_CREATE_UPDATE_REQUEST: 'CAN_CREATE_UPDATE_REQUEST',
  // eslint-disable-next-line max-len
  CAN_CLOSE_ATTESTATION_AFTER_SUPERVISION: 'CAN_CLOSE_ATTESTATION_AFTER_SUPERVISION',
  CAN_CREATE_INITIAL_REQUEST: 'CAN_CREATE_INITIAL_REQUEST',
  CAN_RENEW_REQUEST: 'CAN_RENEW_REQUEST',
  CAN_UPDATE_SITUATION: 'CAN_UPDATE_SITUATION',
  CAN_CREATE_SITUATION_UPDATE_REQUEST: 'CAN_CREATE_SITUATION_UPDATE_REQUEST'
};

export const INSTITUTION_ATTESTATION_LIST_ACTIONS = {
  CAN_DISABLE_ATTESTATION: 'CAN_DISABLE_ATTESTATION',
  CAN_REMOVE_SUSPENDED_ATTESTATION: 'CAN_REMOVE_SUSPENDED_ATTESTATION',
  CAN_REACTIVATE_ATTESTATION: 'CAN_REACTIVATE_ATTESTATION',
  CAN_RESTORE_ATTESTATION: 'CAN_RESTORE_ATTESTATION',
  CAN_CLOSE_REQUEST: 'CAN_CLOSE_REQUEST'
};

export const FLUID_BALANCE_ACTIONS = {
  CAN_EDIT: 'CAN_EDIT',
  CAN_SUBMIT: 'CAN_SUBMIT',
  CAN_START_INSTRUCTION: 'CAN_START_INSTRUCTION',
  CAN_START_SUPERVISION: 'CAN_START_SUPERVISION',
  CAN_INSTRUCT: 'CAN_INSTRUCT',
  CAN_SUPERVISE: 'CAN_SUPERVISE',
  CAN_SEND_BACK_TO_OPERATOR: 'CAN_SEND_BACK_TO_OPERATOR',
  CAN_GET_BACK_TO_EDITION: 'CAN_GET_BACK_TO_EDITION',
  CAN_SEND_BACK_TO_INSTRUCTOR: 'CAN_SEND_BACK_TO_INSTRUCTOR'
};

export const FLUID_BALANCE_SUMMARY_ACTIONS = {
  CAN_ASSIGN_INSTRUCTOR: 'CAN_ASSIGN_INSTRUCTOR',
  CAN_ASSIGN_SUPERVISOR: 'CAN_ASSIGN_SUPERVISOR',
  CAN_REOPEN: 'CAN_REOPEN'
};

export const APPLICATION_ROLES = {
  ADMIN: 'DATAFLUIDES_ADMIN',
  SUPER_ADMIN: 'DATAFLUIDES_SUPER_ADMIN',
  OPERATOR_ADMIN: 'DATAFLUIDES_OPERATOR_ADMIN',
  OPERATOR_ATTESTATION_MANAGER: 'DATAFLUIDES_OPERATOR_ATTESTATION_MANAGER',
  ATTESTATION_INSTRUCTOR: 'DATAFLUIDES_ATTESTATION_INSTRUCTOR',
  ATTESTATION_SUPERVISOR: 'DATAFLUIDES_ATTESTATION_SUPERVISOR',
  FLUID_BALANCE_INSTRUCTOR: 'DATAFLUIDES_FLUID_BALANCE_INSTRUCTOR',
  FLUID_BALANCE_SUPERVISOR: 'DATAFLUIDES_FLUID_BALANCE_SUPERVISOR',
  FLUID_BALANCE_MANAGER: 'DATAFLUIDES_FLUID_BALANCE_MANAGER',
  TRANSLATOR: 'DATAFLUIDES_TRANSLATOR',
  TRANSLATION_VALIDATOR: 'DATAFLUIDES_TRANSLATION_VALIDATOR',
  REQUEST_MANAGER: 'DATAFLUIDES_REQUEST_MANAGER',
  AUDITOR: 'DATAFLUIDES_AUDITOR',
  TOOL_VERIFICATION_LOADER: 'DATAFLUIDES_TOOL_VERIFICATION_LOADER'
};

export const ALL_ROLES = [
  APPLICATION_ROLES.ATTESTATION_INSTRUCTOR,
  APPLICATION_ROLES.ATTESTATION_SUPERVISOR,
  APPLICATION_ROLES.REQUEST_MANAGER,
  APPLICATION_ROLES.ADMIN,
  APPLICATION_ROLES.SUPER_ADMIN,
  APPLICATION_ROLES.AUDITOR,
  APPLICATION_ROLES.FLUID_BALANCE_INSTRUCTOR,
  APPLICATION_ROLES.FLUID_BALANCE_SUPERVISOR,
  APPLICATION_ROLES.FLUID_BALANCE_MANAGER,
  APPLICATION_ROLES.OPERATOR_ADMIN,
  APPLICATION_ROLES.OPERATOR_ATTESTATION_MANAGER,
  APPLICATION_ROLES.TOOL_VERIFICATION_LOADER
];

export const ATTESTATION_EVALUATION_ROLES = [
  APPLICATION_ROLES.ATTESTATION_INSTRUCTOR,
  APPLICATION_ROLES.ATTESTATION_SUPERVISOR
];

export const APPLICATION_ATTESTATION_ROLES = [
  APPLICATION_ROLES.ATTESTATION_INSTRUCTOR,
  APPLICATION_ROLES.ATTESTATION_SUPERVISOR,
  APPLICATION_ROLES.REQUEST_MANAGER,
  APPLICATION_ROLES.ADMIN,
  APPLICATION_ROLES.SUPER_ADMIN
];

export const APPLICATION_FLUID_BALANCE_ROLES = [
  APPLICATION_ROLES.FLUID_BALANCE_INSTRUCTOR,
  APPLICATION_ROLES.FLUID_BALANCE_SUPERVISOR,
  APPLICATION_ROLES.FLUID_BALANCE_MANAGER,
  APPLICATION_ROLES.ADMIN,
  APPLICATION_ROLES.SUPER_ADMIN
];

export const FLUID_BALANCE_EVALUATION_ROLES = [
  APPLICATION_ROLES.FLUID_BALANCE_INSTRUCTOR,
  APPLICATION_ROLES.FLUID_BALANCE_SUPERVISOR
];

export const FLUID_BALANCE_ADMIN_ROLES = [
  APPLICATION_ROLES.FLUID_BALANCE_MANAGER,
  APPLICATION_ROLES.ADMIN,
  APPLICATION_ROLES.SUPER_ADMIN
];

export const APPLICATION_MANAGEMENT_ROLES = [
  APPLICATION_ROLES.ATTESTATION_INSTRUCTOR,
  APPLICATION_ROLES.ATTESTATION_SUPERVISOR,
  APPLICATION_ROLES.REQUEST_MANAGER,
  APPLICATION_ROLES.ADMIN,
  APPLICATION_ROLES.SUPER_ADMIN,
  APPLICATION_ROLES.AUDITOR,
  APPLICATION_ROLES.FLUID_BALANCE_INSTRUCTOR,
  APPLICATION_ROLES.FLUID_BALANCE_SUPERVISOR,
  APPLICATION_ROLES.FLUID_BALANCE_MANAGER,
  APPLICATION_ROLES.TOOL_VERIFICATION_LOADER
];

export const APPLICATION_OPERATOR_ROLES = [
  APPLICATION_ROLES.OPERATOR_ADMIN,
  APPLICATION_ROLES.OPERATOR_ATTESTATION_MANAGER
];

export const APPLICATION_BACK_OFFICE_ADMIN_ROLES = [
  APPLICATION_ROLES.ADMIN,
  APPLICATION_ROLES.SUPER_ADMIN
];

export const APPLICATION_ADMIN_ROLES = [
  APPLICATION_ROLES.ADMIN,
  APPLICATION_ROLES.SUPER_ADMIN,
  APPLICATION_ROLES.OPERATOR_ADMIN
];

export const APPLICATION_ADMIN_ACCESS_ROLES = [
  APPLICATION_ROLES.ADMIN,
  APPLICATION_ROLES.SUPER_ADMIN,
  APPLICATION_ROLES.OPERATOR_ADMIN,
  APPLICATION_ROLES.ATTESTATION_INSTRUCTOR,
  APPLICATION_ROLES.ATTESTATION_SUPERVISOR
];

export const APPLICATION_AUDITS_ROLES = [
  APPLICATION_ROLES.ADMIN,
  APPLICATION_ROLES.AUDITOR,
  APPLICATION_ROLES.SUPER_ADMIN,
  APPLICATION_ROLES.ATTESTATION_INSTRUCTOR,
  APPLICATION_ROLES.ATTESTATION_SUPERVISOR
];

export const APPLICATION_AUDIT_ADMINISTRATION_ROLES = [
  APPLICATION_ROLES.ADMIN,
  APPLICATION_ROLES.SUPER_ADMIN,
  APPLICATION_ROLES.ATTESTATION_INSTRUCTOR,
  APPLICATION_ROLES.ATTESTATION_SUPERVISOR
];

export const BACK_OFFICE_ADMINISTRATION_ROLES = [
  APPLICATION_ROLES.ADMIN,
  APPLICATION_ROLES.SUPER_ADMIN,
  APPLICATION_ROLES.ATTESTATION_INSTRUCTOR,
  APPLICATION_ROLES.ATTESTATION_SUPERVISOR
];

export const FLUID_BALANCE_SYDEREP_EXPORT_ROLES = [
  ...FLUID_BALANCE_ADMIN_ROLES,
  APPLICATION_ROLES.FLUID_BALANCE_SUPERVISOR,
  APPLICATION_ROLES.ATTESTATION_SUPERVISOR
];

export const BACK_OFFICE_FLUID_BALANCE_EXPORT_ROLES = [
  APPLICATION_ROLES.SUPER_ADMIN,
  APPLICATION_ROLES.ADMIN,
  APPLICATION_ROLES.FLUID_BALANCE_INSTRUCTOR,
  APPLICATION_ROLES.FLUID_BALANCE_SUPERVISOR,
  APPLICATION_ROLES.FLUID_BALANCE_MANAGER,
  APPLICATION_ROLES.ATTESTATION_SUPERVISOR
];

export const MINIMAL_TOOL_TYPES = [
  'FLUID_BOTTLE',
  'NEUTRAL_FLUID_BOTTLE',
  'FLEXIBLE_COUPLINGS'
];

export const MENU_ITEMS = [
  {
    title: 'menu.home',
    icon: iconEnum.home.icon,
    pathFunction: () => ROUTES.HOME
  },
  {
    title: 'menu.institutions',
    icon: iconEnum.building.icon,
    pathFunction: () => ROUTES.INSTITUTION.ATTESTATION_LIST,
    accessRights: ALL_ROLES
  },
  {
    title: 'menu.instructions',
    icon: iconEnum.evaluation.icon,
    pathFunction: () => ROUTES.INSTRUCTIONS,
    accessRights: APPLICATION_ATTESTATION_ROLES
  },
  {
    title: 'menu.audits',
    icon: iconEnum.audit.icon,
    pathFunction: () => ROUTES.AUDITS,
    accessRights: ALL_ROLES
  },
  {
    title: 'menu.fluidBalanceCampaign',
    icon: iconEnum.fluidBalance.icon,
    pathFunction: () => ROUTES.FLUID_BALANCE_CAMPAIGN,
    accessRights: ALL_ROLES
  },
  {
    title: 'menu.admin',
    icon: iconEnum.administration.icon,
    pathFunction: () => ROUTES.ADMINISTRATION.MAIN,
    accessRights: APPLICATION_ADMIN_ACCESS_ROLES
  }
];

export const APPLICATIONS = {
  DATAFRIG: {
    logo: `${process.env.PUBLIC_URL}/assets/images/applications/datafrig.png`,
    url: 'https://datafrig.cemafroid.fr/datafrig/e000_login.jsp'
  },
  DATABILAN: {
    logo: `${process.env.PUBLIC_URL}/assets/images/applications/databilan.png`,
    url: 'https://databilan.tecnea.fr/accueil'
  },
  DATAFLUIDES: {
    logo: `${process.env.PUBLIC_URL}/assets/images/applications/datafluides.png`,
    url: 'https://datafluides.tecnea.fr'
  },
  DATAHYDROTEST: {
    logo: `${process.env.PUBLIC_URL}/assets/images/applications/datahydrotest.png`,
    url: 'https://datahydrotest.tecnea.fr'
  },
  DATAC2E: {
    logo: `${process.env.PUBLIC_URL}/assets/images/applications/datac2e.png`,
    url: 'https://datac2e.tecnea.fr/accueil'
  },
  DATAFORM: {
    logo: `${process.env.PUBLIC_URL}/assets/images/applications/dataform.png`,
    url: 'https://dataform.cemafroid.fr/accueil'
  },
  DATATECNEA: {
    logo: `${process.env.PUBLIC_URL}/assets/images/applications/datatecnea.png`,
    url: 'https://datatecnea.tecnea.fr/accueil'
  }
};

export const DAYS = [
  'Lundi',
  'Mardi',
  'Mercredi',
  'Jeudi',
  'Vendredi',
  'Samedi',
  'Dimanche'
];

export const countryItems = {
  france: { value: 0, countryCode: 'fr', label: 'France' }
};

export const STRUCTURE_TYPE = {
  COMPANY: 'COMPANY',
  PARENT_COMPANY: 'PARENT_COMPANY',
  INSTITUTION_MODEL: 'INSTITUTION_MODEL',
  INSTITUTION_GROUP: 'INSTITUTION_GROUP'
};

export const STRUCTURE_LEVEL_ICON = {
  COMPANY: faStoreAlt,
  PARENT_COMPANY: faStoreAlt,
  INSTITUTION_MODEL: faBuilding,
  INSTITUTION_GROUP: faSitemap
};

export const ATTESTATION_FORM_STEPS = [
  {
    id: 1,
    label: 'attestationFormRequest.label.purchaseOrder',
    title: 'attestationFormRequest.title.purchaseOrder',
    tooltip: 'attestationFormRequest.tooltip.purchaseOrder',
    section: SectionEnum.PurchaseOrder
  },
  {
    id: 2,
    label: 'attestationFormRequest.label.contacts',
    title: 'attestationFormRequest.title.contacts',
    tooltip: 'attestationFormRequest.tooltip.contacts',
    section: SectionEnum.Contacts
  },
  {
    id: 3,
    label: 'attestationFormRequest.label.operators',
    title: 'attestationFormRequest.title.operators',
    tooltip: 'attestationFormRequest.tooltip.operators',
    section: SectionEnum.Operators
  },
  {
    id: 4,
    label: 'attestationFormRequest.label.tools',
    title: 'attestationFormRequest.title.tools',
    tooltip: 'attestationFormRequest.tooltip.tools',
    section: SectionEnum.Tooling
  },
  {
    id: 5,
    label: 'attestationFormRequest.label.trackingModality',
    title: 'attestationFormRequest.title.trackingModality',
    tooltip: 'attestationFormRequest.tooltip.trackingModality',
    section: SectionEnum.Tracability
  },
  {
    id: 6,
    label: 'attestationFormRequest.label.transmission',
    title: 'attestationFormRequest.title.transmission',
    tooltip: 'attestationFormRequest.tooltip.transmission',
    section: SectionEnum.Transmission
  },
  {
    id: 6,
    label: 'attestationFormRequest.label.conclusion',
    title: 'attestationFormRequest.title.conclusion',
    tooltip: 'attestationFormRequest.tooltip.conclusion',
    section: SectionEnum.Conclusion
  }
];

export const DISABLE_ELEMENT_STYLE = (theme) => ({
  position: 'relative',
  '&:before': {
    content: '""',
    position: 'absolute',
    bottom: '50%',
    left: 0,
    width: '100%',
    height: '1px',
    display: 'block',
    background: theme.palette.grey.main
  }
});

export const DOCUMENT_TYPE = {
  EXTERNAL_VERIFICATION_REPORT: 'EXTERNAL_VERIFICATION_REPORT',
  INVOICE: 'INVOICE',
  DELIVERY_NOTE: 'DELIVERY_NOTE',
  MAINTENANCE_AND_VERIFICATION_CONTRACT: 'MAINTENANCE_AND_VERIFICATION_CONTRACT',
  SUPPLIER_ATTESTATION_WITH_DELIVERY_DATE: 'SUPPLIER_ATTESTATION_WITH_DELIVERY_DATE',
  INTERNAL_VERIFICATION_REPORT: 'INTERNAL_VERIFICATION_REPORT',
  PROCESS_VERIFICATION: 'PROCESS_VERIFICATION',
  VERIFICATION_MARK: 'VERIFICATION_MARK',
  METROLOGICAL_NOTE_BOOK: 'METROLOGICAL_NOTE_BOOK',
  TEST_PROOF_EN_35421: 'TEST_PROOF_EN_35421',
  CONFORMITY_CERTIFICATE_EN_14624: 'CONFORMITY_CERTIFICATE_EN_14624'
};

export const SPECIFIC_DOCUMENT_TYPES = [
  DOCUMENT_TYPE.CONFORMITY_CERTIFICATE_EN_14624,
  DOCUMENT_TYPE.TEST_PROOF_EN_35421
];

export const REQUEST_TYPE = {
  NEUTRAL: 'NEUTRAL',
  INITIAL: 'INITIAL',
  UPDATE: 'UPDATE',
  SITUATION_UPDATE: 'SITUATION_UPDATE',
  RENEW: 'RENEW',
  COMPLETION: 'COMPLETION',
  AUDIT_COMPLETION: 'AUDIT_COMPLETION'
};

export const VALIDATION_STATUS = {
  INVALID: 'INVALID',
  RELEVANT_INVALID: 'RELEVANT_INVALID',
  VALID: 'VALID',
  UNDEFINED: 'UNDEFINED'
};

export const ATTESTATION_EVALUATION_RESULT = {
  CONFORM: 'CONFORM',
  NOT_CONFORM: 'NOT_CONFORM',
  REFUSED: 'REFUSED'
};

export const ATTESTATION_CATEGORY_COVERAGE = {
  I: ['I', 'II', 'III', 'IV'],
  II: ['II', 'III', 'IV'],
  III: ['III'],
  IV: ['IV'],
  V: ['V', 'V_VHU'],
  V_VHU: ['V_VHU']
};

export const EVALUATION_ROLES = {
  INSTRUCTOR: 'instructor',
  SUPERVISOR: 'supervisor'
};

export const ATTESTATION_STATUS = {
  VALIDATED: 'VALIDATED',
  TO_RENEW: 'TO_RENEW',
  REMOVED: 'REMOVED',
  EXPIRED: 'EXPIRED',
  SUSPENDED: 'SUSPENDED'
};

export const CAMPAIGN_STATUS = {
  OPENED: 'OPENED',
  LOCKED: 'LOCKED'
};

export const FLUID_BALANCE_STATUS = {
  INCOMPLETE: 'INCOMPLETE',
  SUBMITTED: 'SUBMITTED',
  SENT_BACK_FOR_INSTRUCTION: 'SENT_BACK_FOR_INSTRUCTION',
  INSTRUCTING: 'INSTRUCTING',
  TO_SUPERVISE_REFUSED: 'TO_SUPERVISE_REFUSED',
  TO_SUPERVISE_VALIDATED: 'TO_SUPERVISE_VALIDATED',
  SUPERVISING: 'SUPERVISING',
  VALIDATED: 'VALIDATED',
  REFUSED: 'REFUSED',
  SUPPLEMENT_REQUEST: 'SUPPLEMENT_REQUEST'
};

export const FLUID_BALANCE_STATUS_WITH_ALERTS = [
  FLUID_BALANCE_STATUS.REFUSED,
  FLUID_BALANCE_STATUS.INCOMPLETE,
  FLUID_BALANCE_STATUS.SUBMITTED,
  FLUID_BALANCE_STATUS.SUPPLEMENT_REQUEST
];

export const CHART_DATA_STATUS = {
  CONFORM: 'CONFORM',
  NOT_CONFORM: 'NOT_CONFORM',
  OTHER: 'OTHER'
};

export const AUDIT_TYPES = {
  READING_COMMITTEE: 'READING_COMMITTEE',
  SITE_AUDIT: 'SITE_AUDIT',
  COMPLEMENTARY_SITE_AUDIT: 'COMPLEMENTARY_SITE_AUDIT'
};

export const AUDIT_STATUS = {
  PENDING: 'PENDING',
  REFUSED: 'REFUSED',
  FINISHED_COMPLIANT: 'FINISHED_COMPLIANT',
  FINISHED_NON_COMPLIANT: 'FINISHED_NON_COMPLIANT'
};